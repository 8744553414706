export const ru = {
    sidebar: {
        articles: 'Статьи',
        articles_list: 'Список статей',
        new_article: 'Новая статья',
        classifications: 'Классификации',
        categories: 'Категории',
        tags: 'Теги',
        configuration: 'Конфигурация',
        appearance: 'Внешний вид',
        languages: 'Языки',
        administration: 'Администрирование',
        users: 'Пользователи',
        roles: 'Роли',
        change_password: 'Изменить пароль',
        logout: 'Выйти',
        created_by: 'Создано',
        unauthorized_access: 'У вас нет доступа к этому разделу.',
    },
    list_articles: {
        title_articles: 'Статьи',
        create_article: 'Создать статью',
        description: 'Описание',
        title: 'Название',
        status: 'Статус',
        created_at: 'Создано',
        actions: 'Действия',
        edit: 'Изменить',
        delete: 'Удалить',
        draft: 'Черновик',
        published: 'Опубликовано',
        no_article: 'Нет статей',
    },
    form_article: {
        cancel: 'Отмена',
        edit_article: 'Изменить статью',
        new_article: 'Новая статья',
        title: 'Название',
        content: 'Содержание',
        featured_image: 'Изображение',
        categories: 'Категории',
        tags: 'Теги',
        status: 'Статус',
    },      
    list_tags: {
        tags: 'Теги',
        new_tag: 'Новый тег',
        name: 'Название',
        description: 'Описание',
        slug: 'Slug',
        actions: 'Действия',
        edit: 'Изменить',
        delete: 'Удалить',
        no_tag: 'Тегов нет',
    },
    form_tag: {
        cancel: 'Отмена',
        edit_tag: 'Изменить тег',
        new_tag: 'Новый тег',
        name: 'Название',
        description: 'Описание',
        edit: 'Изменить',
        create: 'Создать',
    },
    list_categories: {
        categories: 'Категории',
        new_category: 'Новая категория',
        description: 'Описание',
        name: 'Название',
        slug: 'Slug',
        actions: 'Действия',
        edit: 'Изменить',
        delete: 'Удалить',
        no_category: 'Категорий нет',
    },
    form_category: {
        cancel: 'Отмена',
        edit_category: 'Изменить категорию',
        new_category: 'Новая категория',
        name: 'Название',
        description: 'Описание',
        edit: 'Изменить',
        create: 'Создать',
    },
    users_list: {
        users_management: 'Управление пользователями',
        username: 'Имя пользователя',
        email: 'Email',
        role: 'Роль',
        created_at: 'Создано',
        actions: 'Действия',
        edit: 'Изменить',
        delete: 'Удалить',
        new_user: 'Новый пользователь',
    },  
    users_form: {
        user_form: 'Форма пользователя',
        username: 'Имя пользователя',
        email: 'Email',
        password: 'Пароль',
        role: 'Роль',
        select_role: 'Выбрать роль',
        cancel: 'Отмена',
        create: 'Создать',
        users_form: 'Форма пользователя',
        edit: 'Изменить',
    },  
    roles_list: {
        roles_management: 'Управление ролями',
        name: 'Название',
        description: 'Описание',
        type: 'Тип',
        permissions: 'Права',
        actions: 'Действия',
        system: 'Система',
        new_role: 'Новая роль',
        all_resources: 'Все ресурсы',
        all_permissions: 'Все права',
    },
    roles_form: {
        create: 'Создать',
        name: 'Название',
        description: 'Описание',
        permissions: 'Права',
        cancel: 'Отмена',
        save: 'Сохранить',
    },
    settings: {
        base_colors: 'Цветовые схемы',
        primary: 'Основный цвет',
        secondary: 'Второстепенный цвет',
        accent: 'Акцентный цвет',
        background_colors: 'Цветовые схемы фона',
        background_main: 'Основный цвет фона',
        background_alt: 'Альтернативный цвет фона',
        background_dark: 'Темный цвет фона',
        background_light: 'Светлый цвет фона',
        sidebar_background: 'Цвет фона боковой панели',
        card_colors: 'Цветовые схемы карточек',
        card_background: 'Цвет фона карточек',
        card_border: 'Цвет границ карточек',
        modal_colors: 'Цветовые схемы модальных окон',
        modal_background: 'Цвет фона модальных окон',
        dropdown_background: 'Цвет фона выпадающих списков',
        text_colors: 'Цветовые схемы текста',
        text_primary: 'Основный цвет текста',
        text_secondary: 'Второстепенный цвет текста',
        text_light: 'Светлый цвет текста',
        text_muted: 'Мутный цвет текста',
        text_link: 'Цвет текста ссылки',
        text_hover: 'Цвет текста при наведении',
        sidebar_text: 'Цвет текста боковой панели',
        button_colors: 'Цветовые схемы кнопок',
        button_primary_bg: 'Цвет фона основной кнопки',
        button_primary_text: 'Цвет текста основной кнопки',
        button_secondary_bg: 'Цвет фона второстепенной кнопки',
        button_secondary_text: 'Цвет текста второстепенной кнопки',
        form_colors: 'Цветовые схемы форм',
        input_background: 'Цвет фона полей ввода',
        input_border: 'Цвет границ полей ввода',
        input_text: 'Цвет текста полей ввода',
        input_placeholder: 'Цвет плейсхолдера полей ввода',
        input_focus_border: 'Цвет границ полей ввода при фокусе',
        status_colors: 'Цветовые схемы статусов',
        success: 'Цвет статуса success',
        warning: 'Цвет статуса warning',
        error: 'Цвет статуса error',
        info: 'Цвет статуса info',
        reset: 'Сбросить',
        save: 'Сохранить',  
        language: 'Язык',
        language_settings: 'Настройки языка',
        language_help_text: 'Выберите язык своего выбора, чтобы персонализировать интерфейс вашего блога.', 
    },
    blog: {
        blog_title: 'Статьи блога',
        by: 'Автор',
        unknown_author: 'Автор неизвестен',
        loading: 'Загрузка статей...',
        no_posts: 'Статей нет',
        category_name: 'Категория',
        tag_name: 'Тег',
        search_placeholder: 'Найти статьи...',
        sort_newest: 'Новые',
        sort_oldest: 'Старые',
        all_categories: 'Все категории',
        all_tags: 'Все теги',
        reset_filters: 'Сбросить фильтры',
        views: 'Просмотров',
        back_to_list: 'Вернуться к списку',
    }   
}; 