import React, { useState } from 'react';
import './styles/faq.css';
import { FaArrowLeft } from 'react-icons/fa';

export const faqData = [
  {
    question: "Pourquoi as-tu créé ce blog ?",
    answer: "Pour partager mes expériences en développement, mes projets open source et aider d'autres développeurs ou débutant avec des astuces et des tutoriels."
  },
  {
    question: "Quelle est la différence entre ce blog et ton portfolio ?",
    answer: "Mon portfolio est une vitrine professionnelle pour mes projets, mes compétences et mes expériences, alors que ce blog est un espace pour approfondir mes idées, partager mes connaissances, mes savoir-faire et écrire sur le développement au quotidien."
  },
  {
    question: "Quels outils/utilitaires utilises-tu pour développer ?",
    answer: "Un fork de Visual Studio Code, Git, Docker, Node.js, etc. Je partage une liste complète dans mon portfolio."
  },
  {
    question: "Peut-on utiliser ou modifier le code que tu partages ici ?",
    answer: "Oui en partie ! Le code que je partage sur mon blog et GitHub est généralement sous licence open source (vérifie la licence sur le projet celle ci peut différer)."
  },
  {
    question: "Proposes-tu des tutoriels pour les débutants ?",
    answer: "C'est dans les projets d'évolution de ce blog, de proposer des tutoriels pour les débutants. Docker, Server mail, React, etc."
  },
  {
    question: "Quels types de projets développes-tu ?",
    answer: "Je travaille sur des outils web pour simplifié certaines tâches, des expériences front-end interactives et intuitive, et des automations pour simplifier mes tâches quotidiennes."
  },
  {
    question: "Puis-je contribuer à tes projets ?",
    answer: "Oui ! Si un projet t'intéresse, rends-toi sur mon GitHub et ouvre une issue ou une pull request."
  },
  {
    question: "À quelle fréquence publies-tu des articles et projets ?",
    answer: "J’essaie de publier un nouvel article toutes les semaines, mais cela peut varier selon les projets en cours."
  },
  {
    question: "Proposes-tu de l’aide personnalisée ?",
    answer: "Tu peux me contacter via mon formulaire de contact."
  },
  {
    question: "Quel est ton stack préféré ?",
    answer: "En se moment, j’adore travailler avec React, Node.js et Docker, mais j’explore constamment de nouvelles technologies."
  }
];

export const toggleAccordion = (index, activeIndex, setActiveIndex) => {
  setActiveIndex(activeIndex === index ? null : index);
};

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h1>Questions Fréquentes</h1>
        <p className="faq-description">
          Retrouvez ici les réponses aux questions les plus fréquemment posées sur le blog et son fonctionnement.
        </p>
      </div>

      <div className="faq-content">
        {faqData.map((item, index) => (
          <div 
            key={index} 
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          >
            <button 
              className="faq-question" 
              onClick={() => toggleAccordion(index, activeIndex, setActiveIndex)}
            >
              {item.question}
              <span className="faq-icon"><FaArrowLeft /></span>
            </button>
            <div className="faq-answer">
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;