import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.blog.logandelmairedev.com',  // URL de base de l'API
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance; 