import React from 'react';
import './styles/LiveProjects.css';
import { useLanguage } from '../blog/translations/LanguageContext';
import { getTranslation } from '../blog/translations/Translation';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

const LiveProjects = () => {
  const { language } = useLanguage();

  const projects = [
    
  ];

  return (
    <div className="live-projects-container">
      <div className="live-projects-header">
        <h1>Projets live</h1>
      </div>

      <div className="projects-grid">
        {projects.map(project => (
          <article key={project.id} className="project-card">
            {project.image && (
              <div className="project-image-container">
                <img src={project.image} alt={project.title} className="project-image" />
                <div className="project-status">{project.status}</div>
              </div>
            )}
            
            <div className="project-content">
              <h3>{project.title}</h3>
              <p className="project-description">{project.description}</p>
              
              <div className="project-technologies">
                {project.technologies.map((tech, index) => (
                  <span key={index} className="tech-tag">{tech}</span>
                ))}
              </div>

              <div className="project-links">
                {project.demoUrl && (
                  <a href={project.demoUrl} className="demo-link" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Demo
                  </a>
                )}
                {project.githubUrl && (
                  <a href={project.githubUrl} className="github-link" target="_blank" rel="noopener noreferrer">
                    <FaGithub /> Code
                  </a>
                )}
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default LiveProjects;
