export const fr = {
    sidebar: {
        articles: 'Articles',
        articles_list: 'Liste des articles',
        new_article: 'Nouvel article',
        classifications: 'Classifications',
        categories: 'Catégories',
        tags: 'Tags',
        configuration: 'Configuration',
        appearance: 'Apparence',
        languages: 'Langues',
        administration: 'Administration',
        users: 'Utilisateurs',
        roles: 'Rôles',
        change_password: 'Changer mot de passe',
        logout: 'Déconnexion',
        created_by: 'Créé par',
        unauthorized_access: 'Vous n\'avez pas les droits d\'accès à cette section.',
    },
    list_articles: {
        title_articles: 'Articles',
        create_article: 'Créer un article',
        description: 'Description',
        title: 'Titre',
        status: 'Statut',
        created_at: 'Date de création',
        actions: 'Actions',
        edit: 'Modifier',
        delete: 'Supprimer',
        draft: 'Brouillon',
        published: 'Publié',
        no_article: 'Aucun article n\'a été créé',
    },
    form_article: {
        cancel: 'Annuler',
        edit_article: 'Modifier l\'article',
        new_article: 'Nouvel article',
        title: 'Titre',
        content: 'Contenu',
        featured_image: 'Image à la une',
        categories: 'Catégories',
        tags: 'Tags',
        status: 'Statut',
        click_or_drag_image: 'Cliquez ou glissez une image ici',
        new_category: 'Nouvelle catégorie',
        new_tag: 'Nouveau tag',
        modification: 'Modification...',
        creation: 'Création...',
        edit: 'Modifier',
        create: 'Créer',
        draft: 'Brouillon',
        published: 'Publié',
    },
    list_tags: {
        tags: 'Tags',
        new_tag: 'Créer un tag',
        name: 'Nom',
        description: 'Description',
        slug: 'Slug',
        actions: 'Actions',
        edit: 'Modifier',
        delete: 'Supprimer',
        no_tag: 'Aucun tag n\'a été créé',
    },
    form_tag: {
        cancel: 'Annuler',
        edit_tag: 'Modifier le tag',
        new_tag: 'Nouveau tag',
        name: 'Nom',
        description: 'Description',
        edit: 'Modifier',
        create: 'Créer',
    },
    list_categories: {
        categories: 'Catégories',
        new_category: 'Créer une catégorie',
        name: 'Nom',
        description: 'Description',
        slug: 'Slug',
        actions: 'Actions',
        edit: 'Modifier',
        delete: 'Supprimer',
        no_category: 'Aucune catégorie n\'a été créée',
    },
    form_category: {
        cancel: 'Annuler',
        edit_category: 'Modifier la catégorie',
        new_category: 'Nouvelle catégorie',
        name: 'Nom',
        description: 'Description',
        edit: 'Modifier',
        create: 'Créer',
    },
    users_list: {
        users_management: 'Gestion des utilisateurs',
        username: 'Nom d\'utilisateur',
        email: 'Email',
        role: 'Rôle',
        created_at: 'Date de création',
        actions: 'Actions',
        edit: 'Modifier',
        delete: 'Supprimer',
        new_user: 'Nouvel utilisateur',
    },
    users_form: {
        user_form: 'Formulaire d\'utilisateur',
        username: 'Nom d\'utilisateur',
        email: 'Email',
        password: 'Mot de passe',
        role: 'Rôle',
        select_role: 'Sélectionner un rôle',
        cancel: 'Annuler',
        create: 'Créer',
        users_form: 'Formulaire d\'utilisateur',
        edit: 'Modifier',
    },
    roles_list: {
        roles_management: 'Gestion des rôles',
        name: 'Nom',
        description: 'Description',
        type: 'Type',
        permissions: 'Permissions',
        actions: 'Actions',
        system: 'Systeme',
        new_role: 'Nouveau rôle',
        all_resources: 'Toutes les ressources',
        all_permissions: 'Tous les droits',
    },
    roles_form: {
        create: 'Créer',
        name: 'Nom',
        description: 'Description',
        permissions: 'Permissions',
        cancel: 'Annuler',
        save: 'Enregistrer',
        new_role: 'Nouveau rôle',
        all_resources: 'Toutes les ressources',
        all_permissions: 'Tous les droits',
    },
    settings: {
        base_colors: 'Couleurs de base',
        primary: 'Couleur primaire',
        secondary: 'Couleur secondaire',
        accent: 'Couleur accent',
        background_colors: 'Couleurs de fond',
        background_main: 'Couleur de fond principale',
        background_alt: 'Couleur de fond alternative',
        background_dark: 'Couleur de fond sombre',
        background_light: 'Couleur de fond claire',
        sidebar_background: 'Couleur de fond du sidebar',
        card_colors: 'Couleurs des cartes',
        card_background: 'Couleur de fond des cartes',
        card_border: 'Couleur de bordure des cartes',
        modal_colors: 'Couleurs des modales',
        modal_background: 'Couleur de fond des modales',
        dropdown_background: 'Couleur de fond des dropdowns',
        text_colors: 'Couleurs du texte',
        text_primary: 'Couleur du texte primaire',
        text_secondary: 'Couleur du texte secondaire',
        text_light: 'Couleur du texte clair',
        text_muted: 'Couleur du texte muté',
        text_link: 'Couleur du texte du lien',
        text_hover: 'Couleur du texte au survol',
        sidebar_text: 'Couleur du texte du sidebar',
        button_colors: 'Couleurs des boutons',
        button_primary_bg: 'Couleur de fond du bouton primaire',
        button_primary_text: 'Couleur du texte du bouton primaire',
        button_secondary_bg: 'Couleur de fond du bouton secondaire',
        button_secondary_text: 'Couleur du texte du bouton secondaire',
        form_colors: 'Couleurs des formulaires',
        input_background: 'Couleur de fond des inputs',
        input_border: 'Couleur de bordure des inputs',
        input_text: 'Couleur du texte des inputs',
        input_placeholder: 'Couleur du placeholder des inputs',
        input_focus_border: 'Couleur de bordure des inputs au focus',
        status_colors: 'Couleurs de statut',
        success: 'Couleur de statut success',
        warning: 'Couleur de statut warning',
        error: 'Couleur de statut error',
        info: 'Couleur de statut info',
        reset: 'Réinitialiser',
        save: 'Enregistrer',
        language: 'Langue',
        language_settings: 'Paramètres de la langue',
        language_help_text: 'Sélectionnez la langue de votre choix pour personnaliser l\'interface de votre blog.',
    },
    blog: {
        blog_title: 'Articles du Blog',
        by: 'Par',
        unknown_author: 'Auteur inconnu',
        loading: 'Chargement des articles...',
        no_posts: 'Aucun article publié pour le moment.',
        category_name: 'Catégorie',
        tag_name: 'Tag',
        search_placeholder: 'Rechercher des articles...',
        sort_newest: 'Plus récent',
        sort_oldest: 'Plus ancien',
        all_categories: 'Toutes les catégories',
        all_tags: 'Tous les tags',
        reset_filters: 'Réinitialiser les filtres',
        views: 'Vues',
        back_to_list: 'Retour à la liste',
    }
}; 