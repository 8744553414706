import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './variable.css';
import './navbar.css';
import { useLanguage } from '../translation/LanguageContext';
import { getTranslation } from '../translation/Translation';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to="/" className="nav-logo">
            <img src="/img/logo.png" alt="Logo" className="logo" />
            <span>Logan@Blog</span>
          </Link>
          <button className="hamburger" onClick={toggleMenu}>
            <span className={`hamburger-line ${isOpen ? 'open' : ''}`}></span>
            <span className={`hamburger-line ${isOpen ? 'open' : ''}`}></span>
            <span className={`hamburger-line ${isOpen ? 'open' : ''}`}></span>
          </button>
        </div>

        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>{getTranslation(language, 'navbar.home')}</Link></li>
          <li><Link to="/blog/articles" onClick={toggleMenu}>{getTranslation(language, 'navbar.articles')}</Link></li>
          <li><Link to="/live-projects" onClick={toggleMenu}>{getTranslation(language, 'navbar.live_projects')}</Link></li>
          <li><Link to="/github" onClick={toggleMenu}>{getTranslation(language, 'navbar.github')}</Link></li>
          <li><Link to="/faq" onClick={toggleMenu}>{getTranslation(language, 'navbar.faq')}</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
