import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/github.css';
import { useLanguage } from '../translation/LanguageContext';
import { getTranslation } from '../translation/Translation';
import logger from '../blog/utils/logger';

// Composant pour les 3 derniers projets
export const LastGithubProjects = () => {
  const [latestRepos, setLatestRepos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await axios.get('https://api.github.com/users/TeekRoxx/repos');
        const reposData = response.data;
        
        const latest = reposData
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .slice(0, 3);
        
        setLatestRepos(latest);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des repos:', error);
        setLoading(false);
      }
    };

    fetchRepos();
  }, []);

  if (loading) {
    return <div className="tempory"><p>Chargement...</p></div>;
  }

  if (!latestRepos.length) {
    return <div className="tempory"><p>Aucun projet disponible</p></div>;
  }

  return (
    <>
      {latestRepos.map(repo => (
        <article key={repo.id} className="repo-card">
          <div className="repo-content">
            <h3 className="repo-title">{repo.name}</h3>
            
            {repo.description && (
              <p className="repo-description">{repo.description}</p>
            )}

            <div className="repo-meta">
              {repo.language && (
                <span className="repo-language">
                  <span className={`language-dot ${repo.language.toLowerCase()}`}></span>
                  {repo.language}
                </span>
              )}
              
              <div className="repo-stats">
                <span className="repo-stars">⭐ {repo.stargazers_count}</span>
                <span className="repo-forks">🔄 {repo.forks_count}</span>
              </div>
            </div>

            <div className="repo-footer">
              <span className="repo-date">
                Mis à jour le {new Date(repo.updated_at).toLocaleDateString('fr-FR')}
              </span>
              <a 
                href={repo.html_url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="repo-link"
              >
                Voir sur GitHub →
              </a>
            </div>
          </div>
        </article>
      ))}
    </>
  );
};

// Composant principal pour la page GitHub
const GithubProjects = () => {
  const { language } = useLanguage();
  const [repos, setRepos] = useState([]);
  const [filteredRepos, setFilteredRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('updated');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await axios.get('https://api.github.com/users/TeekRoxx/repos');
        const reposData = response.data;
        
        // Extraire les langages uniques
        const uniqueLanguages = [...new Set(reposData
          .map(repo => repo.language)
          .filter(lang => lang !== null))];
        
        setLanguages(uniqueLanguages);
        setRepos(reposData);
        setFilteredRepos(reposData);
        setLoading(false);
      } catch (error) {
        logger.error('Erreur lors de la récupération des repos:', error);
        setLoading(false);
      }
    };

    fetchRepos();
  }, []);

  useEffect(() => {
    let updatedRepos = [...repos];

    // Filtrage par recherche
    if (searchTerm) {
      updatedRepos = updatedRepos.filter(repo =>
        repo.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (repo.description && repo.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    // Filtrage par langage
    if (selectedLanguage) {
      updatedRepos = updatedRepos.filter(repo => repo.language === selectedLanguage);
    }

    // Tri
    switch (sortOrder) {
      case 'updated':
        updatedRepos.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        break;
      case 'stars':
        updatedRepos.sort((a, b) => b.stargazers_count - a.stargazers_count);
        break;
      case 'name':
        updatedRepos.sort((a, b) => a.name.localeCompare(b.name));
        break;
      default:
        break;
    }

    setFilteredRepos(updatedRepos);
  }, [searchTerm, sortOrder, selectedLanguage, repos]);

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedLanguage('');
    setSortOrder('updated');
  };

  if (loading) {
    return <div className="loading">Chargement des projets...</div>;
  }

  return (
    <div className="github-container">
      <h2>Projets GitHub</h2>

      <div className="controls">
        <div className="filters-group">
          <input
            type="text"
            placeholder="Rechercher un projet..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="filter-select"
          >
            <option value="">Tous les langages</option>
            {languages.map(language => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>

          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="sort-select"
          >
            <option value="updated">Plus récents</option>
            <option value="stars">Plus étoilés</option>
            <option value="name">Alphabétique</option>
          </select>
        </div>

        <button 
          onClick={resetFilters}
          className="reset-button"
          title="Réinitialiser les filtres"
        >
          ↺
        </button>
      </div>

      <div className="repos-grid">
        {filteredRepos.map(repo => (
          <article key={repo.id} className="repo-card">
            <div className="repo-content">
              <h3 className="repo-title">{repo.name}</h3>
              
              {repo.description && (
                <p className="repo-description">{repo.description}</p>
              )}

              <div className="repo-meta">
                {repo.language && (
                  <span className="repo-language">
                    <span className={`language-dot ${repo.language.toLowerCase()}`}></span>
                    {repo.language}
                  </span>
                )}
                
                <div className="repo-stats">
                  <span className="repo-stars">
                    ⭐ {repo.stargazers_count}
                  </span>
                  <span className="repo-forks">
                    🔄 {repo.forks_count}
                  </span>
                </div>
              </div>

              <div className="repo-footer">
                <span className="repo-date">
                  Mis à jour le {new Date(repo.updated_at).toLocaleDateString('fr-FR')}
                </span>
                <a 
                  href={repo.html_url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="repo-link"
                >
                  Voir sur GitHub →
                </a>
              </div>
            </div>
          </article>
        ))}
      </div>
      {filteredRepos.length === 0 && (
        <div className="tempory">
          <p>Aucun projet disponible</p>
        </div>
      )}
    </div>
  );
};

export default GithubProjects;
