export const en = {
    sidebar: {
        articles: 'Articles',
        articles_list: 'Articles list',
        new_article: 'New article',
        classifications: 'Classifications',
        categories: 'Categories',
        tags: 'Tags',
        configuration: 'Configuration',
        appearance: 'Appearance',
        languages: 'Languages',
        administration: 'Administration',
        users: 'Users',
        roles: 'Roles',
        change_password: 'Change password',
        logout: 'Logout',
        created_by: 'Created by',
        unauthorized_access: 'You do not have access to this section.',
    },
    list_articles: {
        title_articles: 'Articles',
        create_article: 'Create article',
        description: 'Description',
        title: 'Title',
        status: 'Status',
        created_at: 'Created at',
        actions: 'Actions',
        edit: 'Edit',
        delete: 'Delete',
        draft: 'Draft',
        published: 'Published',
        no_article: 'No article created',
    },
    form_article: {
        cancel: 'Cancel',
        edit_article: 'Edit article',
        new_article: 'New article',
        title: 'Title',
        content: 'Content',
        featured_image: 'Featured image',
        categories: 'Categories',
        tags: 'Tags',
        status: 'Status',
        click_or_drag_image: 'Click or drag an image here',
        new_category: 'New category',
        new_tag: 'New tag',
        modification: 'Modification...',
        creation: 'Creation...',
        edit: 'Edit',
        create: 'Create',
        draft: 'Draft',
        published: 'Published',
    },
    list_tags: {
        tags: 'Tags',
        new_tag: 'Create tag',
        name: 'Name',
        description: 'Description',
        slug: 'Slug',
        actions: 'Actions',
        edit: 'Edit',
        delete: 'Delete',
        no_tag: 'No tag created',
    },
    form_tag: {
        cancel: 'Cancel',
        edit_tag: 'Edit tag',
        new_tag: 'New tag',
        name: 'Name',
        description: 'Description',
        edit: 'Edit',
        create: 'Create',
    },
    list_categories: {
        categories: 'Categories',
        new_category: 'Create category',
        description: 'Description',
        name: 'Name',
        slug: 'Slug',
        actions: 'Actions',
        edit: 'Edit',
        delete: 'Delete',
        no_category: 'No category created',
    },
    form_category: {
        cancel: 'Cancel',
        edit_category: 'Edit category',
        new_category: 'New category',
        name: 'Name',
        description: 'Description',
        edit: 'Edit',
        create: 'Create',
    },
    users_list: {
        users_management: 'User management',
        username: 'Username',
        email: 'Email',
        role: 'Role',
        created_at: 'Created at',
        actions: 'Actions',
        edit: 'Edit',
        delete: 'Delete',
        new_user: 'New user',
    },
    users_form: {
        user_form: 'User form',
        username: 'Username',
        email: 'Email',
        password: 'Password',
        role: 'Role',
        select_role: 'Select role',
        cancel: 'Cancel',
        create: 'Create',
        users_form: 'User form',
        edit: 'Edit',
    },
    roles_list: {
        roles_management: 'Roles management',
        name: 'Name',
        description: 'Description',
        type: 'Type',
        permissions: 'Permissions',
        actions: 'Actions',
        system: 'System',
        new_role: 'New role',
        all_resources: 'All resources',
        all_permissions: 'All permissions',
    },
    roles_form: {
        create: 'Create',
        name: 'Name',
        description: 'Description',
        permissions: 'Permissions',
        cancel: 'Cancel',
        save: 'Save',
    },
    settings: {
        base_colors: 'Base colors',
        primary: 'Primary color',
        secondary: 'Secondary color',
        accent: 'Accent color',
        background_colors: 'Background colors',
        background_main: 'Main background color',
        background_alt: 'Alternative background color',
        background_dark: 'Dark background color',
        background_light: 'Light background color',
        sidebar_background: 'Sidebar background color',
        card_colors: 'Card colors',
        card_background: 'Card background color',
        card_border: 'Card border color',
        modal_colors: 'Modal colors',
        modal_background: 'Modal background color',
        dropdown_background: 'Dropdown background color',
        text_colors: 'Text colors',
        text_primary: 'Primary text color',
        text_secondary: 'Secondary text color',
        text_light: 'Light text color',
        text_muted: 'Muted text color',
        text_link: 'Link text color',
        text_hover: 'Hover text color',
        sidebar_text: 'Sidebar text color',
        button_colors: 'Button colors',
        button_primary_bg: 'Primary button background color',
        button_primary_text: 'Primary button text color',
        button_secondary_bg: 'Secondary button background color',
        button_secondary_text: 'Secondary button text color',
        form_colors: 'Form colors',
        input_background: 'Input background color',
        input_border: 'Input border color',
        input_text: 'Input text color',
        input_placeholder: 'Input placeholder color',
        input_focus_border: 'Input focus border color',
        status_colors: 'Status colors',
        success: 'Success status color',
        warning: 'Warning status color',
        error: 'Error status color',
        info: 'Info status color',
        reset: 'Reset',
        save: 'Save',
        language: 'Language',
        language_settings: 'Language settings',
        language_help_text: 'Select the language of your choice to personalize the interface of your blog.',
    },
    blog: {
        blog_title: 'Blog Articles',
        by: 'By',
        unknown_author: 'Unknown author',
        loading: 'Loading articles...',
        no_posts: 'No published articles at the moment.',
        category_name: 'Category',
        tag_name: 'Tag',
        search_placeholder: 'Search articles...',
        sort_newest: 'Newest',
        sort_oldest: 'Oldest',
        all_categories: 'All categories',
        all_tags: 'All tags',
        reset_filters: 'Reset filters',
        views: 'Views',
        back_to_list: 'Back to list',
    }
}; 