export const es = {
    sidebar: {
        articles: 'Artículos',
        articles_list: 'Lista de artículos',
        new_article: 'Nuevo artículo',
        classifications: 'Clasificaciones',
        categories: 'Categorías',
        tags: 'Etiquetas',
        configuration: 'Configuración',
        appearance: 'Apariencia',
        languages: 'Idiomas',
        administration: 'Administración',
        users: 'Usuarios',
        roles: 'Roles',
        change_password: 'Cambiar contraseña',
        logout: 'Cerrar sesión',
        created_by: 'Creado por',
        unauthorized_access: 'No tienes acceso a esta sección.',
    },
    list_articles: {
        title_articles: 'Artículos',
        create_article: 'Crear artículo',
        description: 'Descripción',
        title: 'Título',
        status: 'Estado',
        created_at: 'Creado el',
        actions: 'Acciones',
        edit: 'Editar',
        delete: 'Eliminar',
        draft: 'Borrador',
        published: 'Publicado',
        no_article: 'No hay artículos creados',
    },
    form_article: {
        cancel: 'Cancelar',
        edit_article: 'Editar artículo',
        new_article: 'Nuevo artículo',
        title: 'Título',
        content: 'Contenido',
        featured_image: 'Imagen destacada',
        categories: 'Categorías',
        tags: 'Etiquetas',
        status: 'Estado',
        click_or_drag_image: 'Haz clic o arrastra una imagen aquí',
        new_category: 'Nueva categoría',
        new_tag: 'Nueva etiqueta',
        modification: 'Modificación...',
        creation: 'Creación...',
        edit: 'Editar',
        create: 'Crear',
        draft: 'Borrador',
        published: 'Publicado',
    },
    list_tags: {
        tags: 'Etiquetas',
        new_tag: 'Crear etiqueta',
        name: 'Nombre',
        description: 'Descripción',
        slug: 'Slug',
        actions: 'Acciones',
        edit: 'Editar',
        delete: 'Eliminar',
        no_tag: 'No hay etiquetas creadas',
    },
    form_tag: {
        cancel: 'Cancelar',
        edit_tag: 'Editar etiqueta',
        new_tag: 'Nueva etiqueta',
        name: 'Nombre',
        description: 'Descripción',
        edit: 'Editar',
        create: 'Crear',
    },
    list_categories: {
        categories: 'Categorías',
        new_category: 'Crear categoría',
        description: 'Descripción',
        name: 'Nombre',
        slug: 'Slug',
        actions: 'Acciones',
        edit: 'Editar',
        delete: 'Eliminar',
        no_category: 'No hay categorías creadas',
    },
    form_category: {
        cancel: 'Cancelar',
        edit_category: 'Editar categoría',
        new_category: 'Nueva categoría',
        name: 'Nombre',
        description: 'Descripción',
        edit: 'Editar',
        create: 'Crear',
    },
    users_list: {
        users_management: 'Gestión de usuarios',
        username: 'Nombre de usuario',
        email: 'Email',
        role: 'Rol',
        created_at: 'Creado el',
        actions: 'Acciones',
        edit: 'Editar',
        delete: 'Eliminar',
        new_user: 'Nuevo usuario',
    },
    users_form: {
        user_form: 'Formulario de usuario',
        username: 'Nombre de usuario',
        email: 'Email',
        password: 'Contraseña',
        role: 'Rol',
        select_role: 'Seleccionar rol',
        cancel: 'Cancelar',
        create: 'Crear',
        users_form: 'Formulario de usuario',
        edit: 'Editar',
    },  
    roles_list: {
        roles_management: 'Gestión de roles',
        name: 'Nombre',
        description: 'Descripción',
        type: 'Tipo',
        permissions: 'Permisos',
        actions: 'Acciones',
        system: 'Sistema',
        new_role: 'Nuevo rol',
        all_resources: 'Todas las ressources',
        all_permissions: 'Todos los permisos',
    },
    roles_form: {
        create: 'Crear',
        name: 'Nombre',
        description: 'Descripción',
        permissions: 'Permisos',
        cancel: 'Cancelar',
        save: 'Guardar',
    },
    settings: {
        base_colors: 'Colores de base',
        primary: 'Color primario',
        secondary: 'Color secundario',
        accent: 'Color de acento',
        background_colors: 'Colores de fondo',
        background_main: 'Color de fondo principal',
        background_alt: 'Color de fondo alternativo',
        background_dark: 'Color de fondo oscuro',
        background_light: 'Color de fondo claro',
        sidebar_background: 'Color de fondo del sidebar',
        card_colors: 'Colores de las tarjetas',
        card_background: 'Color de fondo de las tarjetas',
        card_border: 'Color de borde de las tarjetas',
        modal_colors: 'Colores de las modales',
        modal_background: 'Color de fondo de las modales',
        dropdown_background: 'Color de fondo de los dropdowns',
        text_colors: 'Colores del texto',
        text_primary: 'Color del texto primario',
        text_secondary: 'Color del texto secundario',
        text_light: 'Color del texto claro',
        text_muted: 'Color del texto mutado',
        text_link: 'Color del texto del enlace',
        text_hover: 'Color del texto al pasar el ratón',
        sidebar_text: 'Color del texto del sidebar',
        button_colors: 'Colores de los botones',
        button_primary_bg: 'Color de fondo del botón primario',
        button_primary_text: 'Color del texto del botón primario',
        button_secondary_bg: 'Color de fondo del botón secundario',
        button_secondary_text: 'Color del texto del botón secundario',
        form_colors: 'Colores de los formularios',
        input_background: 'Color de fondo de los inputs',
        input_border: 'Color de borde de los inputs',
        input_text: 'Color del texto de los inputs',
        input_placeholder: 'Color del placeholder de los inputs',
        input_focus_border: 'Color de borde de los inputs al pasar el ratón',
        status_colors: 'Colores de estado',
        success: 'Color de estado success',
        warning: 'Color de estado warning',
        error: 'Color de estado error',
        info: 'Color de estado info',
        reset: 'Restablecer',
        save: 'Guardar',
        language: 'Idioma',
        language_settings: 'Configuración del idioma',
        language_help_text: 'Selecciona el idioma de tu elección para personalizar la interfaz de tu blog.',
    },
    blog: {
        blog_title: 'Artículos del Blog',
        by: 'Por',
        unknown_author: 'Autor desconocido',
        loading: 'Cargando artículos...',
        no_posts: 'No hay artículos publicados por ahora.',
        category_name: 'Categoría',
        tag_name: 'Etiqueta',
        search_placeholder: 'Buscar artículos...',
        sort_newest: 'Más reciente',
        sort_oldest: 'Más antiguo',
        all_categories: 'Todas las categorías',
        all_tags: 'Todas las etiquetas',
        reset_filters: 'Restablecer filtros',
        views: 'Vistas',
        back_to_list: 'Volver a la lista',
    }
}; 