import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/home.css';
import {FaArrowRight, FaArrowLeft} from 'react-icons/fa';
import { LastGithubProjects } from './github';
import GithubProjects from './github';
import LastPost from '../blog/components/LastPost';
import { faqData, toggleAccordion } from './faq';

const Home = () => {
  const [latestProjects, setLatestProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { latestRepos, loading: githubLoading } = GithubProjects();
  const [activeIndex, setActiveIndex] = useState(null);
  const [randomFaqItems, setRandomFaqItems] = useState([]);

  useEffect(() => {
    const shuffledFaq = [...faqData]
      .sort(() => Math.random() - 0.5)
      .slice(0, 4);
    setRandomFaqItems(shuffledFaq);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer les derniers projets
        const projectsResponse = await axios.get('/projects');
        
        // Combiner les projets du portfolio avec les projets GitHub
        const portfolioProjects = projectsResponse.data.slice(0, 2); // Prendre les 2 derniers projets du portfolio
        const githubProjects = latestRepos.slice(0, 1); // Prendre le dernier projet GitHub

        const combinedProjects = [
          ...portfolioProjects,
          ...githubProjects.map(repo => ({
            _id: `github-${repo.id}`,
            title: repo.name,
            description: repo.description || 'Pas de description disponible',
            technologies: [repo.language, ...repo.topics],
            link: repo.url,
            isGithub: true,
            updatedAt: repo.updatedAt
          }))
        ];

        setLatestProjects(combinedProjects);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      }
    };

    if (!githubLoading) {
      fetchData();
    }
  }, [latestRepos, githubLoading]);

  return (
    <div className="home-container">
      <section className="hero-section">
        <div className="hero-content">
          <h1>Blog <span className="highlight">Développement</span></h1>
          <p className="hero-description">
          Découvrez mes derniers articles et projets interactifs, 
          conçus dans une démarche d’optimisation et de simplification 
          de l’expérience utilisateur, axés sur le développement web 
          et applicatif, l’automatisation des tâches répétitives et 
          mes retours d’expérience.
          </p>
        </div>
      </section>

      <section className="latest-posts">
        <h2>Derniers Articles</h2>
        <div className="posts-grid">
          <LastPost/>
        </div>
        <Link to="/articles" className="view-all">Voir tous les articles <FaArrowRight/></Link>
      </section>
      
      <section className="latest-projects">
        <h2>Derniers Projets en Live</h2>
        <div className="projects-grid">
          <div className="tempory">
            <p>On going</p>
          </div>
        </div>
        <Link to="/live-projects" className="view-all">Voir tous les projets <FaArrowRight/></Link>
      </section>

      <section className="github-projects">
        <h2>Derniers Projets Disponibles sur GitHub</h2>
        <div className="projects-grid">
          <LastGithubProjects />
        </div>
        <Link to="/github" className="view-all">Voir tous les projets <FaArrowRight/></Link>
      </section>

      <section className="faq">
        <h2>Aperçu FAQ</h2>
        <div className="faq-grid">
          {randomFaqItems.map((item, index) => (
            <div 
              key={index} 
              className={`post-faq ${activeIndex === index ? 'active' : ''}`}
            >
              <button 
                className="faq-question" 
                onClick={() => toggleAccordion(index, activeIndex, setActiveIndex)}
              >
                {item.question}
                <span className="faq-icon"><FaArrowLeft /></span>
              </button>
              <div className="faq-answer">
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
        <Link to="/faq" className="view-all">Voir toutes les questions <FaArrowRight/></Link>
      </section>
    </div>
  );
};

export default Home;
