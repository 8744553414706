import { fr } from './languages/fr';
import { en } from './languages/en'

export const translations = {
    fr,
    en
};

export const getTranslation = (lang, key) => {
    const keys = key.split('.');
    let translation = translations[lang];
    
    for (const k of keys) {
      if (translation && translation[k]) {
        translation = translation[k];
      } else {
        return key;
      }
    }
    
    return translation;
};