import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './component/navbar';
import { LanguageProvider } from './translation/LanguageContext';
import { AuthProvider } from './blog/contexts/AuthContext';
import { LanguageBlogProvider } from './blog/translations/LanguageContext';
import BlogList from './blog/components/BlogList';
import BlogPost from './blog/components/BlogPost';
import AdminPanel from './blog/components/AdminPanel';
import Home from './pages/home';
import LiveProjects from './pages/LiveProjects';
import GithubProjects from './pages/github';
import Faq from './pages/faq';
import Footer from './component/Footer';
import ScrollToTop from './component/ScrollToTop';
import ThemeLoader from './blog/components/ThemeLoader';

function AppContent() {
  const location = useLocation()
  const [nextPath, setNextPath] = useState(null);

  const handleNavigation = (path) => {
    if (path !== location.pathname) {
      setNextPath(path);
    }
  };

  return (
    <div className="App">
      <Navbar />
      <main className='main-content'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/articles" element={<BlogList />} />
          <Route path="/blog/article/:id" element={<BlogPost />} />
          <Route path="/live-projects" element={<LiveProjects />} />
          <Route path="/github" element={<GithubProjects />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog-admin-panel" element={<AdminPanel />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <LanguageBlogProvider>
        <LanguageProvider>
          <Router>
            <ThemeLoader />
            <ScrollToTop />
            <AppContent />
          </Router>
        </LanguageProvider>
      </LanguageBlogProvider>
    </AuthProvider>
  );
}

export default App;
